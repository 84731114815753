import { Component, ChangeDetectionStrategy, OnInit, OnDestroy, Inject } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Location } from "@angular/common";
import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject } from "rxjs";
import { HighChartDataBase, XmlReportDataService } from "@otimo/highcharts-xmlreport";
import { ParamData, ParametersDataService, PeriodData } from "@otimo/highcharts-parameters";
import * as moment from "moment";

import { LangService, SharedReportsService, RouteDataService, DrillDownConfig, ReportPageBase } from "@otimo/report-base";
import { MessagingService } from "@otimo/messaging";

import { APP_SETTINGS, AppSettings } from "app/app.settings";
import { PortalChartConfig } from "&models/portalChartConfig";
import { GlobalReportConfig } from "&models/reportConfig";

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: "app-report",
    templateUrl: "./report.component.html",
    styleUrls: ["./report.component.scss"]
})
export class ReportComponent
    extends ReportPageBase
    implements OnInit, OnDestroy {

    //Common strings (overrides ReportPageBase)
    saveFilterForAllReportsName = "saveFilterForAllPalliativReports";
    commonFiltersForAllReportsName = "commonFiltersForAllPalliativReports";

    //Report settings (overrides ReportPageBase)
    protected chartHeight = 500;
    public config: BehaviorSubject<PortalChartConfig> = new BehaviorSubject<PortalChartConfig>(null);
    protected declare prevConfig: PortalChartConfig;

    // DataLoading
    public mainChartIsLoaded = new BehaviorSubject<boolean>(false);
    public printDataIsLoaded = new BehaviorSubject<boolean>(false);

    //Params
    startdatParam = new ParamData("Startdatum", "startdat", null);
    stopdatParam = new ParamData("Slutdatum", "stopdat", null);
    alderParam = new ParamData("Åldersgrupper", "aldersgrupper", []);
    avlidenplatsParam = new ParamData("Avlidenplats", "avlidenplats", []);
    covidParam = new ParamData("Covid-19", "covid", []);
    diagCancerParamData = new ParamData("Cancer", "diagCancer", []);
    diagHjartsjukdomParamData = new ParamData("Hjärtsjukdom", "diagHjartsjukdom", []);
    diagLungsjukdomParamData = new ParamData("Lungsjukdom", "diagLungsjukdom", []);
    diagDemensParamData = new ParamData("Kognitiv sjukdom", "diagDemens", []);
    diagStrokeParamData = new ParamData("Stroke", "diagStroke", []);
    diagAnnanNeurologisksjukdomParamData = new ParamData("Annan neurologisk sjukdom", "diagAnnanNeurologisksjukdom", []);
    diagInfektionParamData = new ParamData("Infektion", "diagInfektion", []);
    diagDiabetesParamData = new ParamData("Diabetes", "diagDiabetes", []);
    diagFrakturParamData = new ParamData("Fraktur", "diagFraktur", []);
    diagMultipelParamData = new ParamData("Multipel", "diagMultipel", []);
    diagOvrigtParamData = new ParamData("Annan", "diagOvrigt", []);
    dodsfallVantatParam = new ParamData("Förväntat dödsfall", "dodsfallVantat", []);
    enhetParam = new ParamData("Enhet", "enhet", []);
    extAndligParam = new ParamData("Andlig", "extAndlig", []);
    extAnnanSjukhusenhetParam = new ParamData("Annan sjukhusenhet", "extAnnanSjukhusenhet", []);
    extPallTeamParam = new ParamData("Palliativt team", "extPallTeam", []);
    extParamedicinareParam = new ParamData("Paramedicinare", "extParamedicinare", []);
    extSmartenhetParam = new ParamData("Smärtenhet", "extSmartenhet", []);
    extAnnanParam = new ParamData("Annan", "extAnnan", []);
    kommunParam = new ParamData("Län/kommun", "kommun", []);
    konParam = new ParamData("Kön", "kon", []);

    constructor(
        @Inject(APP_SETTINGS) private env: AppSettings,
        protected location: Location,
        protected route: ActivatedRoute,
        protected router: Router,
        protected xmlReportService: XmlReportDataService,
        protected sharedReportsService: SharedReportsService,
        protected paramService: ParametersDataService,
        protected messaging: MessagingService,
        protected routeDataService: RouteDataService,
        protected langService: LangService,
        protected translate: TranslateService
    ) {
        super(location, route, router, xmlReportService, sharedReportsService, messaging, routeDataService, langService, translate);

        this.route.paramMap.subscribe(async (params) => {
            this.saveFiltersForAllReports.next(this.isSavingForAllReports());
            this.reportId = params.get("id"); //Rapportnamn

            this.mainChartIsLoaded.next(false);
            this.printDataIsLoaded.next(false);

            this.loadReportConfig();
            this.loadParamSettings();
            this.loadDrillDown();
            this.loadElearning();

            this.loadConfig(); // Måste köras sist när allt annat är laddat
        });
    }

    ngOnInit() {
        this.groupingsSub = this.xmlReportService.currentGroupings.subscribe(() => {
            if (this.config.getValue() != null &&
                this.config.getValue().grouping != null &&
                this.config.getValue().grouping.length > 0)
            {
                this.currentGrouping.next(this.config.getValue().grouping);
            }
        });

        this.langSub = LangService.currentLang.subscribe((currentLang) => {
            //Stäng urvalsmeny om den är öppen
            if (this.sideNavIsVisible) this.toggleSideNav();

            if (!currentLang) {
                return;
            }

            this.language.next(currentLang);

            if (this.transSub) {
                this.transSub.unsubscribe();
            }
            //Invänta att translate-component ändrat språk och texter har lästs in
            this.transSub = this.translate.getTranslation(currentLang)
                .subscribe((obj) => {
                    this.transSub = null;
                    this.langHasInit.next(true);
                    this.setLanguageInConfig();
                });
        });
    }

    ngOnDestroy() {
        if (this.groupingsSub) {
            this.groupingsSub.unsubscribe();
        }
        if (this.langSub) {
            this.langSub.unsubscribe();
        }
        if (this.transSub) {
            this.transSub.unsubscribe();
        }
        if (this.configSub) {
            this.configSub.unsubscribe();
        }
    }

    /* Laddar rapport-config */
    protected loadReportConfig(): void {
        this.reportConfig = GlobalReportConfig.get(this.reportId);
        if (!this.reportConfig) {
            this.translate.get("general.invalid-report").subscribe((message: string) => {
                this.loadReportError(message);
                this.navigateTo("home");
            });
        }
    }

    /* Innehåller logik för att bestämma vilka filter som skall användas i rapporten */
    protected loadConfig() {
        this.configSub = this.config.subscribe(newConfig => {
            // Kontrollera om det är en ny rapport (eller ändrade urval) som sparats till config, om det är det => rita om highcharts
            if (newConfig && (!this.prevConfig || newConfig != this.prevConfig)) {
                this.prevConfig = newConfig;
                // (Rapporten döljs i 250ms för att sen visas igen för då ritas highcharts om)
                this.showHighcharts.next(false);
                setTimeout(() => this.showHighcharts.next(true), 250);
            }
        });

        // (Timeout på 750ms för att undvika att annat inte hunnit laddas innan vi skapar rapporten)
        setTimeout( () =>
        {
            this.route.queryParamMap.subscribe((queryparams) => {
                if ((this.drillDownConfig && this.drillDownConfig.isDrillUp) ||
                    (this.drillDownInfo.isDrillDowned && localStorage.getItem(this.reportId) != null)) {
                    // Detta är en drilldown / drillup. Ladda urval från LS för att matcha/återställa förälder. Prio 1
                    const localSavedConfigRaw = JSON.parse(localStorage.getItem(this.reportId));
                    const localSavedConfig = new PortalChartConfig(this.env.baseHref);
                    Object.assign(localSavedConfig, localSavedConfigRaw);
                    if (localSavedConfig.configIsValid()) {
                        localSavedConfig.language = this.language.getValue();
                        this.config.next(localSavedConfig);
                    } else {
                        this.setDefaultConfig(); //Ogiltig config, använd default
                    }
                    this.setFiltersFromConfig();
                } else if (queryparams.get(this.savedFilterQueryName)) {
                    //Har sparade filterinställningar i query-params? Prio 2
                    this.loadFilterSettings(queryparams.get(this.savedFilterQueryName));
                } else if (this.isSavingForAllReports() && this.getCommonFilters()) {
                    //Vill använda sparade urval för alla rapporter. Prio 3
                    this.config.next(this.getCommonFilters());
                    this.setFiltersFromConfig();
                    this.saveConfigToLocalStorage();
                } else {
                    this.setDefaultConfig(); //Sätt defaultfilter. Prio 4
                    this.setFiltersFromConfig();
                }
            });
        }, 750);
    }

    /* Hämtar gemensamma urval och använder för aktuell rapport */
    protected getCommonFilters(): PortalChartConfig {
        const commonFiltersForAll = localStorage.getItem(this.commonFiltersForAllReportsName);
        if (!commonFiltersForAll) {
            return null;
        }
        const parsedConfigRaw: PortalChartConfig = JSON.parse(localStorage.getItem(this.commonFiltersForAllReportsName));
        const parsedConfig = new PortalChartConfig(this.env.baseHref);
        Object.assign(parsedConfig, parsedConfigRaw);
        if (!parsedConfig.configIsValid()) {
            return null;
        }
        parsedConfig.reportName = this.reportId;
        parsedConfig.language = this.language.getValue();
        return parsedConfig;
    }

    /* Om det finns språk-inställningar, spara ner det i config */
    protected setLanguageInConfig(): void {
        const currentConfig = this.config.getValue();
        if (currentConfig) {
            const newConfig = new PortalChartConfig(this.env.baseHref);
            Object.assign(newConfig, currentConfig); //(ChangeDetection fungerar endast på objekt om hela objektet byts ut.)
            newConfig.language = LangService.currentLang.getValue();
            this.config.next(newConfig);
        }
    }

    /**
     * Tar emot chartData från component
     *
     * !!! OBS !!! Override för att sätta mainChartIsLoaded
     */
    async receiveChartData(chartData: HighChartDataBase) {
        this.chartData.next(chartData);
        this.currentGrouping.next(chartData.grouping);
        this.mainChartIsLoaded.next(true);

        if (chartData.skipParams && chartData.skipParams.length > 0) {
            const settings = this.paramSettings.getValue();
            settings.skipParams = settings.skipParams.concat(chartData.skipParams);
            this.paramSettings.next(settings);
        }
    }

    /**
     * Drilldown: T.ex. en kolumn har klickats
     */
    async clickedCategory(clickedCategory: string) {
        const chartData = this.chartData.getValue();
        if (chartData == null)
            return;

        const chartDataTypes = this.paramService.getChartDataTypes(chartData.grouping);
        if (this.reportConfig.canDrillDown) {
            // En drilldown behöver samma urval i period, avd mm, som dess förälder.
            let chartConfig: PortalChartConfig = JSON.parse(localStorage.getItem(this.reportId));
            chartConfig.reportName = this.reportConfig.drillDownTo;
            chartConfig.grouping = "";
            chartConfig = this.loadDrillDownParams(chartConfig, chartData.grouping, chartDataTypes, clickedCategory);
            localStorage.setItem(this.reportConfig.drillDownTo, JSON.stringify(chartConfig));
            this.routeDataService.setSingleReadData(new DrillDownConfig(true, false, this.reportId)); // Visar i GUI att detta är en drilldown
            this.navigateTo("report/" + this.reportConfig.drillDownTo);
        }
    }

    /**
     * Användaren har klickat "Gå tillbaka" på en drilldown-rapport
     */
    public drilledUp(): void {
        this.routeDataService.setSingleReadData(new DrillDownConfig(false, true)); // Visar i GUI att detta är en drilldown
        if (this.drillDownConfig) {
            this.navigateTo("report/" + this.drillDownConfig.parentId);
        } else {
            this.location.back();
        }
    }

    /**
     * Förbereder drilldown genom att ställa filterna för perioder, avd etc enligt aktuella urval och klickad drilldownkategori
     */
    protected loadDrillDownParams(chartConfig: PortalChartConfig, grouping: string, chartDataTypes: string[], clickedCategory: string): PortalChartConfig {
        chartDataTypes.forEach((datatype) => {
            this.setConfigFromDataType(datatype, grouping, clickedCategory, chartConfig);
        });
        return chartConfig;
    }

    /**
     * Beräknar config-objektets urval baserat på drilldownkategori
     */
    protected setConfigFromDataType(dataType: string, grouping: string, clickedDataItem: string, chartConfig: PortalChartConfig): void {
        switch (dataType) {
            case "Period":
                this.paramService.convertPeriodToDates(grouping, clickedDataItem, chartConfig);
                break;
            default: break;
        }
    }

    /* Sätt config till dess default-värden */
    protected setDefaultConfig() {
        const defaultConfig = this.getDefaultConfig();
        this.config.next(defaultConfig);
        this.saveConfigToLocalStorage();
        this.configHasDefaultValues.next(true);
    }

    /* Hämta config med default-värden */
    protected getDefaultConfig(): PortalChartConfig {
        const defaultConfig = new PortalChartConfig(this.env.baseHref);
        defaultConfig.chartWidth = this.chartWidth;
        defaultConfig.chartHeight = this.chartHeight;
        defaultConfig.language = this.language.getValue();
        defaultConfig.reportName = this.reportId;
        defaultConfig.grouping = "";
        this.setDefaultPeriod(defaultConfig);
        this.setDefaultVantat(defaultConfig)
        return defaultConfig;
    }


    /* Väntat/Oväntat dödsfall */
    private setDefaultVantat(defaultConfig: PortalChartConfig) {
        defaultConfig.dodsfallVantat = [1]
    }


    private setDefaultPeriod(defaultConfig: PortalChartConfig) {
        const maxYear = this.reportConfig.paramSettings.maxYear;
        const minYear = this.reportConfig.paramSettings.minYear;
        let subtrMaxYearMonths = 0;
        defaultConfig.startdat = this.getPeriodStart(minYear, maxYear, subtrMaxYearMonths);
        defaultConfig.periodstart = [defaultConfig.startdat];
        subtrMaxYearMonths = 1;
        defaultConfig.stopdat = this.getPeriodSlut(maxYear, subtrMaxYearMonths);
        defaultConfig.periodslut = [defaultConfig.stopdat];
        defaultConfig.currentYear = false;
    }

    private getPeriodStart(minYear, maxYear, subtrMaxYearMonths): string {
        const now = moment();
        const currentYear = now.format("YYYY");
        let periodStart = moment();
        if (minYear && maxYear)
            if (minYear == maxYear)
                periodStart = moment(minYear.toString() + "0101");
            else
                if (maxYear.toString() == currentYear)
                    periodStart = moment((maxYear - 1).toString() + now.format("MMDD"));
                else
                    periodStart = moment((maxYear - 1).toString() + "1231");
        else if (!minYear && maxYear)
            periodStart = moment((maxYear - 1).toString() + "1231");
        else if (minYear && !maxYear)
            if (minYear.toString() == currentYear)
                periodStart = moment(minYear.toString() + "0101");
            else
                periodStart = now.subtract(1, "years");
        else
            periodStart = now.subtract(1, "years");

        if (subtrMaxYearMonths)
            periodStart = periodStart.subtract(subtrMaxYearMonths, "months");

        return periodStart.format("YYYYMM");
    }

    private getPeriodSlut(maxYear, subtrMaxYearMonths): string {
        const now = moment();
        let periodSlut = moment();
        if (maxYear) {
            if (maxYear.toString() == now.format("YYYY"))
                periodSlut = moment(maxYear.toString() + now.format("MMDD"));
            else
                periodSlut = moment(maxYear.toString() + "1231");
        }
        else periodSlut = now;

        if (subtrMaxYearMonths)
            periodSlut = periodSlut.subtract(subtrMaxYearMonths, "months");

        return periodSlut.format("YYYYMM");
    }

    /* Rensa alla urval */
    public clearParams() {
        localStorage.clear();                       //Rensa sparade urval
        this.saveFiltersForAllReports.next(false);  //Rensa inställning "Spara för alla rapporter"
        this.setDefaultConfig();                    //Sätt default urval i config
        this.setFiltersFromConfig();                //Sätt urval från config
        this.removeFilterFromQuery();               //Ta bort urval från querystring i url
    }

    /* Instantierar Utdataportalen-filter från config-rådata */
    protected setFiltersFromConfig() {
        this.startdatParam = new ParamData(this.startdatParam.name, this.startdatParam.configId, this.config.getValue().startdat);
        this.stopdatParam = new ParamData(this.stopdatParam.name, this.stopdatParam.configId, this.config.getValue().stopdat);
        this.alderParam = new ParamData(this.alderParam.name, this.alderParam.configId, this.config.getValue().aldersgrupper);
        this.covidParam = new ParamData(this.covidParam.name, this.covidParam.configId, this.config.getValue().covid);
        this.diagCancerParamData.data = this.config.getValue().diagCancer;
        this.diagHjartsjukdomParamData.data = this.config.getValue().diagHjartsjukdom;
        this.diagLungsjukdomParamData.data = this.config.getValue().diagLungsjukdom;
        this.diagDemensParamData.data = this.config.getValue().diagDemens;
        this.diagStrokeParamData.data = this.config.getValue().diagStroke;
        this.diagAnnanNeurologisksjukdomParamData.data = this.config.getValue().diagAnnanNeurologisksjukdom;
        this.diagInfektionParamData.data = this.config.getValue().diagInfektion;
        this.diagDiabetesParamData.data = this.config.getValue().diagDiabetes;
        this.diagFrakturParamData.data = this.config.getValue().diagFraktur;
        this.diagMultipelParamData.data = this.config.getValue().diagMultipel;
        this.diagOvrigtParamData.data = this.config.getValue().diagOvrigt;
        this.dodsfallVantatParam = new ParamData(this.dodsfallVantatParam.name, this.dodsfallVantatParam.configId, this.config.getValue().dodsfallVantat);
        this.enhetParam = new ParamData(this.enhetParam.name, this.enhetParam.configId, this.config.getValue().enhet);
        this.extAndligParam.data = this.config.getValue().extAndlig;
        this.extAnnanSjukhusenhetParam.data = this.config.getValue().extAnnanSjukhusenhet;
        this.extPallTeamParam.data = this.config.getValue().extPallTeam;
        this.extParamedicinareParam.data = this.config.getValue().extParamedicinare;
        this.extSmartenhetParam.data = this.config.getValue().extSmartenhet;
        this.extAnnanParam.data = this.config.getValue().extAnnan;
        this.kommunParam = new ParamData(this.kommunParam.name, this.kommunParam.configId, this.config.getValue().kommun);
        this.konParam = new ParamData(this.konParam.name, this.konParam.configId, this.config.getValue().kon);
        this.avlidenplatsParam = new ParamData(this.avlidenplatsParam.name, this.avlidenplatsParam.configId, this.config.getValue().avlidenplats);
        this.params.next([this.startdatParam, this.stopdatParam, this.alderParam, this.covidParam, this.enhetParam, this.konParam, this.avlidenplatsParam, this.kommunParam, this.dodsfallVantatParam,
                            this.diagCancerParamData, this.diagHjartsjukdomParamData, this.diagLungsjukdomParamData, this.diagDemensParamData, this.diagStrokeParamData,
                            this.diagAnnanNeurologisksjukdomParamData, this.diagInfektionParamData, this.diagDiabetesParamData, this.diagFrakturParamData, this.diagMultipelParamData, this.diagOvrigtParamData,
            this.extAndligParam, this.extAnnanSjukhusenhetParam, this.extPallTeamParam, this.extParamedicinareParam, this.extSmartenhetParam, this.extAnnanParam ]);

        this.resetSaveUserReport();
        this.checkIfDefaultConfig();
    }

    /* Ett urval har uppdaterats. Sätt nytt config och trigga uppdatering av rapport */
    public updateParam(paramData: ParamData) {
        this.mainChartIsLoaded.next(false);
        this.printDataIsLoaded.next(false);

        const currentConfig = this.config.getValue();
        const newConfig = new PortalChartConfig(this.env.baseHref);
        Object.assign(newConfig, currentConfig); //(ChangeDetection fungerar endast på objekt om hela objektet byts ut.)

        //Matcha paramData mot respektive configObjekt på rapporten
        newConfig[paramData.configId] = paramData.data;

        this.updateConfigAndTrigger(newConfig);
    }

    /* Perioddata har uppdaterats */
    public periodChange(periodData: PeriodData) {
        const currentConfig = this.config.getValue();
        const newConfig = new PortalChartConfig(this.env.baseHref);
        Object.assign(newConfig, currentConfig); //(ChangeDetection fungerar endast på objekt om hela objektet byts ut.)

        newConfig.periodstart = [periodData.periodFrom.data];
        newConfig.periodslut = [periodData.periodTo.data];
        newConfig.startdat = newConfig.periodstart[0];
        newConfig.stopdat = newConfig.periodslut[0];
        newConfig.currentYear = false;

        this.updateConfigAndTrigger(newConfig);
    }

    /* Uppdatera config och trigga nedladdning av ny rapportdata */
    protected updateConfigAndTrigger(newConfig: PortalChartConfig): void {
        this.config.next(newConfig);
        this.resaveConfig();
        this.checkIfDefaultConfig();
    }

    /**
     * Höjd av diagram ändrad
     * (anropas från <xml-report>)
     */
    public currentChartHeight = "M";
    public chartHeightChange(height: any) {
        this.currentChartHeight = height;

        const currentConfig = this.config.getValue();
        const newConfig = new PortalChartConfig(this.env.baseHref);
        Object.assign(newConfig, currentConfig); //(ChangeDetection fungerar endast på objekt om hela objektet byts ut.)

        switch (height) {
            case "S":
                newConfig.chartHeight = this.chartHeight - 150;
                break;
            case "M":
                newConfig.chartHeight = this.chartHeight;
                break;
            case "L":
                newConfig.chartHeight = this.chartHeight + 150;
                break;
            case "XL":
                newConfig.chartHeight = this.chartHeight + 300;
                break;
            default:
                break;
        }

        this.config.next(newConfig);
        this.resaveConfig();
    }

    /* Spara rapport under inloggning */
    public saveUserReport(): void {
        if (!this.canSaveUserReport.getValue()) {
            this.shareReport().subscribe((sharedReport) => {
                window.location.href = this.env.getUserReportUrl(this.language.getValue()) +
                    "?reporturl=" +
                    encodeURIComponent(window.location.href) +
                    "&sharedreportid=" +
                    encodeURIComponent(sharedReport.id);
            });
        } else {
            window.location.href = this.env.getUserReportUrl + "new?reporturl=" +
                encodeURIComponent(window.location.href) + "&sharedreportid=" +
                encodeURIComponent(this.currentSharedReportId);
        }
    }

    /* Gruppering ändrad */
    public changeGrouping(grouping) {
        const currentConfig = this.config.getValue();
        const newConfig = new PortalChartConfig(this.env.baseHref);
        Object.assign(newConfig, currentConfig); //(ChangeDetection fungerar endast på objekt om hela objektet byts ut.)
        newConfig.grouping = grouping;
        this.config.next(newConfig);
        this.resaveConfig();
    }

    /**
     * Triggas när <report-print> laddats klart
     * (Specifikt för Palliativ-portalen)
     */
    public whenPrintDataIsLoaded() {
        this.printDataIsLoaded.next(true);
    }
}
