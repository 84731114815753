import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { AppModule } from "./app/app.module";
import { env } from "./environments/env";
import { APP_SETTINGS, AppSettings } from "app/app.settings";
import { structuredMerge } from "./functions/structuredMerge";

const baseHref = (document.getElementsByTagName("base")[0] || {}).href;

fetch(`${baseHref}api/settings`)
    .then((response) => response.json())
    .then((backendSettings) => {
        if (env.production) {
            enableProdMode();
            window.console.debug = function () { /* disable any console.debug debugging statements in production mode */ };
        }

        // Merge environment with settings
        const settings = new AppSettings();
        structuredMerge(settings, env, backendSettings);
        // Get href from base element
        if (settings.baseHref == "")
            settings.baseHref = baseHref;

        if (!env.production)
            console.log(settings);

        platformBrowserDynamic([{ provide: APP_SETTINGS, useValue: settings }])
            .bootstrapModule(AppModule)
            .catch((err) => console.error(err));
    });
