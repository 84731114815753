import { Component } from "@angular/core";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"]
})
export class HomeComponent {


    welcome = "Välkommen till Svenska palliativregistrets utdataportal!";
    text1 = "Här presenteras de inrapporterade resultaten fortlöpande för både Dödsfallsenkäten och Närståendeenkäten. En inskickad komplett registrering hamnar direkt i portalen efter tekniskt godkänd validering, vilket innebär att resultaten ändras varje minut.";
    text2 = "Det är <u>Svenska</u> palliativregistrets önskemål att <b>samtliga</b> dödsfall i Sverige skall rapporteras och att Närståendeenkäten erbjuds till en närstående för <b>alla</b> registrerade dödsfall. I utdataportalen visas urvalet ”väntade dödsfall” som förvalt för samtliga frågor.";
    text3 = "Det finns data i dödsfallsregistret ända ifrån 2005, men av jämförbarhetsskäl kan du i den här portalen bara se data ifrån 2011 och framåt. Observera att frågor i dödsfallsenkäten har ändrats under åren. Konsekvensen av det är att vissa resultat bara finns för en begränsad tidsperiod. Därmed kommer du inte att se resultat för hela den tidsperioden du försöker välja ibland.";
    text4 = "Utdataportalen för Närståendeenkäten redovisar svaren på enkätfrågorna och ger även en överblick av hur många som besvarat enkäten utifrån utskickade inbjudningar.";
    text5 = "För Närståendeenkäten visas data från januari 2021, data för tidigare enkäter kan erhållas genom separat förfrågan om datauttag. Data med mindre än två utskickade enkäter redovisas ej i utdataportalen.";

}
