
<footer id="footer">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-6 col-lg-4">
                <h6>Svenska palliativregistret</h6>
                <p>
                    Box 601<br>
                    391 26 Kalmar
                </p>
            </div>
            <div class="col-md-6 col-lg-4 my-4 my-md-0">
                <h6>Kontakta oss</h6>
                <p>
                    Telefon: 0480-418040<br>
                    E-post: <a href="mailto:info@palliativregistret.se">info@palliativregistret.se</a>
                </p>
            </div>
        </div>
        <div class="row d-flex justify-content-center">
            <div class="col-md-3">
                <div class="text-center" title="{{version}}">
                    Powered by <a href="http://www.otimo.se/" target="_blank">
                        <img src="assets/images/otimo_logo_vit.svg" class="otimo-logo" />
                    </a>
                </div>
            </div>
        </div>
    </div>
</footer>
