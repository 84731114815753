import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";
import { LangService } from "@otimo/report-base";
import { OidcSecurityService } from "angular-auth-oidc-client";
import { CookieService } from "ngx-cookie-service";

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.css"]
})
export class AppComponent implements OnInit {
    constructor(
        lang: LangService,
        private translate: TranslateService,
        private titleService: Title,
        private oidcService: OidcSecurityService,
        private cookieService: CookieService
    ) {
        lang.init();

        this.translate.onLangChange.subscribe(() => {
            this.translate.get("header.title").subscribe((text: string) => {
                this.titleService.setTitle(text);
            });
        });
    }

    ngOnInit(): void {
    // Work in progress
        //this.oidcService.checkAuth().subscribe(loginResponse => {
        //    const { isAuthenticated, userData, accessToken, idToken, configId } = loginResponse;

        //    console.log(loginResponse);

        ////    this.oidcService.getConfiguration().subscribe(config => console.log(config));
        ////    console.log(loginResponse);

        ////    const cookie = this.cookieService.get("palliativ");
        ////    if (isAuthenticated) {
        ////        if (cookie == "")
        ////            this.oidcService.logoffLocal();
        ////    }

        ////    if (!isAuthenticated && cookie == "1")
        ////        this.oidcService.authorize();
        //});
        return;
    }
}
